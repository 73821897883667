import { Avatar, Button, Card, Menu, Stack, Typography, TextField, Box, MenuItem, LinearProgress, FormControlLabel, Switch } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh'
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef } from "react";
import io from "socket.io-client";
import socket from "./sockets";
import { RHFTextField } from "../../../components/hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const CryptoJS = require("crypto-js");

function extractYouTubeVideoId(url) {
  // Regular expressions to match various YouTube URL formats
  const patterns = [
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtu.be\/([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_\-?]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?list=[a-zA-Z0-9_-]+&v=([a-zA-Z0-9_-]+)/,
    /(?:https?:\/\/)?(?:www\.)?youtube-nocookie\.com\/embed\/([a-zA-Z0-9_-]+)/,
  ];

  // Try each pattern and return the video ID if a match is found
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }

  // If no match is found, return null or handle the case as needed
  return null;
}

const YoutubeLive = () => {
  const { lectId, lectTitle, lectDesc, ytUrl } = useParams();
  let newLink = "";
  const decodedYtUrl = decodeURIComponent(ytUrl);

  Array.from(decodedYtUrl).forEach((elem) => {
    if (elem !== "@") newLink += elem;
    else newLink += "/";

    // console.log('newLin', newLink)
  });
  const showChatHandler = async () => {
    const resp = await axios.put(`${process.env.REACT_APP_LIVE_URL}/chat/hide/${chatId}`, { status: true })
    if (resp.status == 200) {
      toast.success(resp.data.message)
      // getHistory();
    }
  }
  const hindeChatHandler = async () => {
    const resp = await axios.put(`${process.env.REACT_APP_LIVE_URL}/chat/hide/${chatId}`, { status: false })
    if (resp.status == 200) {
      toast.success(resp.data.message)
      // getHistory();
    }
  }
  // console.log(newLink);
  const videoId = extractYouTubeVideoId(newLink);
  const newUrl = `https://www.youtube.com/embed/${videoId}`;
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const name = detailObject?.username;
  const username = detailObject?.username;
  // console.log(detailObject?.data);
  const id = detailObject?.adminId;
  const profileIcon =
    detailObject?.profilePhoto ??
    "https://storage-upschindi.s3.ap-south-1.amazonaws.com/data/images/avatar.png";
  // let socket = io.connect('http://localhost:3001', { transports: ['websocket'] })
  const [data, setData] = useState([])
  const [state, setState] = useState(false)
  const [msg, setMsg] = useState("");
  const [chatId, setChatId] = useState("")
  const [messagelist, setMessageList] = useState([]);
  const [nameOfUser, setNameOfUser] = useState();
  const [IconIfUser, setIconIfUser] = useState("");
  const [isLiveFullScreen, setIsLiveFullScreen] = useState(false);
  // const containerRef = useRef(null);
  const getHistory = async () => {

    const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/chat/chat-history/${lectId}`);
    console.log("History ", response.data.data)
    setData(response.data.data)
    if (response.status) {
      // toast.success(response.data.message)
    }
  }
  const [pollMode, setPollMode] = useState(false);
  const [pollQuestion, setPollQuestion] = useState("");
  const [pollOptions, setPollOptions] = useState(["", ""]);
  const [polls, setPolls] = useState([]);
  useEffect(() => {
    socket.emit("get-all-polls", lectId, lectId);
    socket.on("all-polls", (pollData) => setPolls(pollData));
    console.log("Polls ", polls)
    return () => {
      socket.off("all-polls");
      socket.off("roomMessages");
    };
  }, [])
  const handleCreatePoll = () => {
    if (pollQuestion.trim() && pollOptions.filter(opt => opt.trim()).length > 1) {
      const pollData = {
        question: pollQuestion,
        options: pollOptions.filter(opt => opt.trim()),
        admin: detailObject?.adminId,
        lecture: lectId,
        roomId: lectId,
      };
      socket.emit("create-poll", pollData);
      setPollQuestion("");
      setPollOptions(["", ""]);
    } else {
      toast.error("Please provide a valid question and at least two options.");
    }
  };
  const joinRoom = () => {
    const roomId = lectId;

    socket.emit("create", roomId);
  };
  const qhandleHideMessage = () => {
    // Close the menu
    handleMenuClose();

    // Initiate the chat hiding process
    hindeChatHandler();
  };

  useEffect(() => {
    joinRoom();
  }, []);
  const sendMessage = async (msgg) => {
    // const msgg
    const roomId = lectId;
    // console.log('line26',details)
    await socket.emit("addUser", { username, id });
    // console.log(msgg  + " "+ name + " "+roomId + " "+ profileIcon);
    await socket.emit("send-message", detailObject?.adminId, msgg, name, roomId, profileIcon);
    setMsg("");
    socket.emit("getMessages", lectId);
    socket.on("roomMessages", (messages) => {
      // toast.success("Chat Fetched Successfully")
      setData(messages); // Set initial chat history
    });
  };


  const handlePollOptionChange = (index, value) => {
    const updatedOptions = [...pollOptions];
    updatedOptions[index] = value;
    setPollOptions(updatedOptions);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = pollOptions.filter((_, i) => i !== index);
    setPollOptions(updatedOptions);
  };
  console.log("Data ", data)
  const arrivalMessage = (message, name, userIconUrl) => {
    // setMessageList((prev) => [
    //   ...prev,
    //   { message: message, name: name, icon: userIconUrl },
    // ]);
  };

  useEffect(() => {
    socket.on("receive-message", (message, name, userIconUrl) => {
      // console.log('line32', message)
      arrivalMessage(message, name, userIconUrl);
      setNameOfUser(name);
      setIconIfUser(userIconUrl);
      // console.log(message)
    });
  }, []);
  // socket.emit("getMessages", lectId);
  //   socket.on("roomMessages", (messages) => {
  //     // toast.success("Chat Fetched Successfully")
  //     setData(messages); // Set initial chat history
  //   });

  useEffect(() => {
    // getHistory()
    socket.emit("getMessages", lectId);
    socket.on("roomMessages", (messages) => {
      // toast.success("Chat Fetched Successfully")
      setData(messages); // Set initial chat history
    });

  }, [state])

  const [users, usersSet] = useState();
  const [lectDetails, setlectureDetails] = useState();
  //Lecture Resource
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    // console.log(lectId);
    async function fetchDetails() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureResourceDetails?Lecture_id=${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // debugger;
      // console.log(responseJson.data);
      usersSet(responseJson.data);
      // setLoading(false)
      // setLoadingTable(false)
      //Lecture Details
      const fullResponseForLecture = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJsonForLecture = await fullResponseForLecture.json();
      setlectureDetails(responseJsonForLecture.data);
    }

    fetchDetails();
  }, []);

  const handleChange = (e) => {
    setMsg(e.target.value);
    // console.log(e.target.value)
    // if (e.target.value !== '') { setMsg(e.target.value) }
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleMenuOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setChatId(message?._id)
    setSelectedMessage(message);
  };

  /**
   * Closes the context menu for a chat message.
   * Called when the user clicks outside of the context menu or when the user closes the menu.
   * @function
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  /**
   * Handles the deletion of a chat message.
   * This function is responsible for executing the logic to delete a chat message.
   * The implementation details need to be added.
   * @function
   * @returns {Promise<void>}
   */
  const handleDelete = async () => {
    // Add delete logic here
    handleMenuClose();

    const token = localStorage.getItem("token");

    const url = `${process.env.REACT_APP_LIVE_URL}/chat/delete/${chatId}`;

    /**
     * The HTTP request method
     * @type {string}
     */
    const method = "PUT";

    /**
     * The request headers
     * @type {import("axios").AxiosRequestHeaders}
     */
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    /**
     * The request body
     * @type {any}
     */
    const data = {};

    /**
     * The response data
     * @type {any}
     */
    const response = await axios.delete(url, { headers });

    /**
     * Handle the response data
     */
    if (response.status == 200) {
      // TODO: Add logic to handle the response data
      toast.success("Message Deleted successfully")
      socket.emit("getMessages", lectId);
      socket.on("roomMessages", (messages) => {
        // toast.success("Chat Fetched Successfully")
        setData(messages); // Set initial chat history
      });
    } else {
      // TODO: Add logic to handle errors
    }
  };
  const deleteMessage = (messageId) => {
    socket.emit("deleteMessage", chatId, lectId);
    toast.success("Message Deleted successfully")

    socket.emit("getMessages", lectId);
    // socket.on("roomMessages", (messages) => {
    //   // toast.success("Chat Fetched Successfully")
    //   setData(messages); // Set initial chat history
    // });
  };

  // Emit hideMessage event
  const hideMessage = (messageId) => {
    socket.emit("hideMessage", chatId, false, lectId);
    toast.success("Message Hided successfully")

    socket.emit("getMessages", lectId);
    // socket.on("roomMessages", (messages) => {
    //   // toast.success("Chat Fetched Successfully")
    //   setData(messages); // Set initial chat history
    // });
  };
  const showMessage = (messageId) => {
    socket.emit("hideMessage", chatId, true, lectId);
    toast.success("Message Visible successfully")

    // socket.emit("getMessages", lectId);
    // socket.on("roomMessages", (messages) => {
    //   // toast.success("Chat Fetched Successfully")
    //   setData(messages); // Set initial chat history
    // });
  };
  return (
    <div
      className="parent"
      style={{
        height: "100%",
      }}
    >
      <ToastContainer position="bottom-center" />
      <Stack direction={isLiveFullScreen ? "column" : "row"} gap="20px">
        <Stack
          direction="column"
          sx={{ width: "100%", height: "80vh !important" }}
        >
          <div
            style={{
              height: isLiveFullScreen ? "70%" : "60%",
              width: "100%",
              padding: "20px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "black",
                borderRadius: "10px",
                marginBottom: "10px",
                overflowY: "auto",
              }}
            >
              <iframe
                width={isLiveFullScreen ? "100%" : "780"}
                height={isLiveFullScreen ? "340" : "255"}
                // src='https://www.youtube.com/embed/DAEnJzUNZYE?si=59XwYssuy9g29qEN'>
                src={newUrl}
                allowFullScreen
              >
                {/* // src={newUrl}> */}
              </iframe>
            </div>
          </div>
          <h5 style={{ height: "5%" }}>{lectDetails?.lecture_title}</h5>
          <div style={{ height: "50%", width: "100%" }}>
            <Card
              sx={{
                height: "100%",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4 style={{}}>{lectDesc}</h4>
              {/* {console.log(users)} */}
              {users?.length !== 0
                ? users?.map((item, index) => {
                  return (
                    <div key={index}>
                      {item?.resourceType == "pdf" ? (
                        <Stack direction="row" alignItems="center">
                          {item?.title}:
                          <a href={item?.upload_file?.fileLoc}>
                            <PictureAsPdfIcon />
                          </a>
                        </Stack>
                      ) : (
                        <p>
                          {item?.title}: {item?.upload_file?.fileLoc}
                        </p>
                      )}
                    </div>
                  );
                })
                : "Nothing"}
            </Card>
          </div>
        </Stack>
        <Stack direction="column" sx={{ width: isLiveFullScreen ? "100%" : "70%" }}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "10px ",
                paddingRight: "10px",
                alignItems: "center",
                background: "rgba(255, 137, 158, 0.12)",
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 24C7.075 24 6.71875 23.8563 6.43125 23.5688C6.14375 23.2812 6 22.925 6 22.5V19.5H25.5V6H28.5C28.925 6 29.2812 6.14375 29.5687 6.43125C29.8562 6.71875 30 7.075 30 7.5V30L24 24H7.5ZM0 22.5V1.5C0 1.075 0.14375 0.71875 0.43125 0.43125C0.71875 0.14375 1.075 0 1.5 0H21C21.425 0 21.7812 0.14375 22.0688 0.43125C22.3563 0.71875 22.5 1.075 22.5 1.5V15C22.5 15.425 22.3563 15.7813 22.0688 16.0688C21.7812 16.3563 21.425 16.5 21 16.5H6L0 22.5ZM19.5 13.5V3H3V13.5H19.5Z"
                  fill="#EA506B"
                  fill-opacity="0.75"
                />
              </svg>
              &nbsp; Live Comments
              <Button onClick={() => setState(!state)}>
                <RefreshIcon />
              </Button>
              <Button onClick={() => setIsLiveFullScreen(!isLiveFullScreen)}>
                {!isLiveFullScreen ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
              </Button>
            </div>

            {/* Tab Toggle */}
            <Box sx={{ display: "flex", justifyContent: "center", margin: "10px 0", width: "100%" }}>
              <Button
                variant={pollMode ? "outlined" : "contained"}
                onClick={() => setPollMode(false)}
                sx={{
                  flex: 1,  // This makes both buttons take equal width
                  borderRadius: "5",  // Optional, to make the edges sharp
                  // backgroundColor: pollMode ? "transparent" : "#F0F8FF",  // Highlight the active button
                  // color: pollMode ? "text.primary" : "white", // Text color change
                }}
              >
                Chat
              </Button>
              <Button
                variant={pollMode ? "contained" : "outlined"}
                onClick={() => setPollMode(true)}
                sx={{
                  flex: 1,  // Equal width for both buttons
                  marginLeft: "10px",
                  borderRadius: "5",  // Optional, to make the edges sharp
                  // backgroundColor: pollMode ? "#F0F8FF" : "transparent",  // Highlight the active button
                  // color: pollMode ? "white" : "text.primary", // Text color change
                }}
              >
                Polls
              </Button>
            </Box>


            {/* Chat Tab */}
            {!pollMode && (
              <div>
                <div
                  style={{
                    overflowY: "auto",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column-reverse",
                    height: isLiveFullScreen ? "50vh" : "60vh",
                    scrollBehavior: "smooth",
                  }}
                >
                  {data?.map((msg, index) => (
                    <Card
                      key={index}
                      sx={{
                        margin: "15px 0",
                        padding: "35px 15px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          <b>{msg?.FullName || msg?.name}</b>
                        </Typography>
                        <Typography variant="body2">{msg?.message}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {msg?.created_at?.slice(0, 10) +
                            " " +
                            msg?.created_at?.slice(11, 19)}
                        </Typography>
                      </div>
                      <IconButton onClick={(e) => handleMenuOpen(e, msg)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedMessage === msg)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => deleteMessage(msg?._id)}>Delete</MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (msg?.status) {
                              hideMessage(msg?._id);
                            } else {
                              showMessage(msg?._id);
                            }
                          }}
                        >
                          {msg?.status ? "Hide" : "Visible"}
                        </MenuItem>
                      </Menu>
                    </Card>
                  ))}
                </div>
                <Stack direction="row" spacing={2} sx={{ padding: "20px" }}>
                  <TextField
                    fullWidth
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    placeholder="Type your message here..."
                  />
                  <Button
                    variant="contained"
                    disabled={!msg.trim()}
                    onClick={() => sendMessage(msg)}
                  >
                    Send
                  </Button>
                </Stack>
              </div>
            )}

            {/* Poll Tab */}
            {pollMode && (
              <div
                style={{
                  padding: "20px",
                  // height: isLiveFullScreen ? "50vh" : "60vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  overflowY: "auto",
                }}
              >
                {/* Poll Display Section */}
                {polls?.map((poll, index) => (
                  <Card
                    key={index}
                    sx={{
                      padding: "15px",
                      marginBottom: "15px",
                      width: "100%",
                      // height: "30vh",
                      backgroundColor: "#F0F8FF",
                      scrollBehavior: "smooth",
                     
                      // overflowY: "auto",
                      // overflowY: "hidden",
                      // maxWidth: "800px", // Constrain the overall poll width
                    }}
                  >
                    {/* Poll Question */}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: "15px",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {poll?.question}
                    </Typography>

                    {/* Poll Options in Row */}
                    <Box
                      sx={{
                        display: "flex",
                        paddingX: "15px",
                        flexDirection: "row", // Horizontal layout for options
                        justifyContent: "flex-start", // Space out options evenly
                        alignItems: "flex-start",
                        height: "auto",
                       gap:"20px"
                      }}
                    >
                      {poll?.options?.map((opt, idx) => {
                        const percentageData = poll?.percentages?.find((p) => p?.option === opt);
                        const percentage = percentageData ? percentageData?.percentage : "0.00";
                        return (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              flexDirection: "column", // Align text and percentage vertically
                              alignItems: "center",
                              justifyContent: "center",
                              width: '100px', // Fixed width for each option
                              padding: "10px",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                              height: "auto",
                              boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "bold",
                                marginBottom: "5px",
                                textAlign: "center",
                              }}
                            >
                              {opt}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#4CAF50",
                                textAlign: "center",
                              }}
                            >
                              {percentage}%
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={parseFloat(percentage)}
                              sx={{
                                width: "100%",
                                height: "5px",
                                marginTop: "5px",
                                borderRadius: "5px",
                                backgroundColor: "#e0e0e0",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "#4CAF50",
                                },
                              }}
                            />
                          </Box>
                        );
                      })}

                    </Box>
                  </Card>
                ))}

                <Stack sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                 
                  {pollMode && (
                    <Stack sx={{ width: "100%", marginTop: "10px" }}>
                      <TextField
                        label="Poll Question"
                        fullWidth
                        value={pollQuestion}
                        onChange={(e) => setPollQuestion(e.target.value)}
                        sx={{ marginBottom: "10px" }}
                      />
                      {pollOptions?.map((option, index) => (
                        <div key={index} style={{ display: "flex", marginBottom: "10px" }}>
                          <TextField
                            label={`Option ${index + 1}`}
                            value={option}
                            onChange={(e) => handlePollOptionChange(index, e.target.value)}
                            fullWidth
                          />
                          {pollOptions?.length > 2 && (
                            <Button onClick={() => handleRemoveOption(index)} sx={{ marginLeft: "10px" }}>Remove</Button>
                          )}
                        </div>
                      ))}

                      <Button
                        variant="outlined"
                        onClick={() => setPollOptions([...pollOptions, ""])}
                        sx={{ marginBottom: "10px" }}
                      >
                        Add Option
                      </Button>

                      <Button
                        variant="contained"
                        onClick={handleCreatePoll}
                        sx={{ marginLeft: "10px" }}
                      >
                        Create Poll
                      </Button>
                    </Stack>
                  )}
                </Stack>

              </div>
            )}
          </Card>

        </Stack>

      </Stack>
    </div>
  );
};
// <Button variant="outlined" onClick={() => setPollOptions([...pollOptions, ""])}>
//   Add Option
// </Button>
// <Button variant="contained" onClick={handleCreatePoll} sx={{ marginLeft: "10px" }}>
//   Create Poll
// </Button>

export default YoutubeLive;