import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  TextField,
  MenuItem,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import ImageIcon from "@mui/icons-material/Image";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "S.No", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "title", label: "Designation", alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  // { id: 'language', label: 'Language', alignRight: false },
  { id: "image", label: "image", alignRight: false },
  // { id: "mobile", label: "Phone Number", alignRight: false },
  { id: "created_at", label: "created_at", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TeacherTable() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();

  const [isActiveValue, setIsActiveValue] = useState(false);
  const token = localStorage.getItem("token");
  async function fetchUsers() {
    const fullResponse = await fetch(
      `${process.env.REACT_APP_LIVE_URL}/mentor/get-mentors`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();
    usersSet(responseJson.data);
    setLoading(false);
    // if (responseJson.msg === 'not an admin') {
    //     localStorage.removeItem("isLoggedIn")
    //     localStorage.removeItem("details")
    //     navigate('/')
    // };
  }
  useEffect(() => {
  

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.namw);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  let helperArray = [];
  filteredUsers.map((item) =>
    helperArray.push({
      id: item._id,
      name: item?.name,
      title: item?.title,
      mobile: item?.mobile,
      createdAt: item?.created_at,
    })
  );
  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  //console.log(updatedValues);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Banner">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <ExportCSV csvData={helperArray} fileName="Banner Data" />
              </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            id,
                            name,
                            _id,
                            title,
                            mobile,
                            image,
                            created_at,
                            status,
                            is_active,
                          } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          let dateObject = new Date(created_at);

                          // Extract the date parts
                          let year = dateObject.getFullYear();
                          let month = String(
                            dateObject.getMonth() + 1
                          ).padStart(2, "0"); // Months are zero-based
                          let day = String(dateObject.getDate()).padStart(
                            2,
                            "0"
                          );

                          // Format the date as YYYY-MM-DD
                          let createdDate = `${year}-${month}-${day}`;
                          // row.sno = index + 1 + rowsPerPage * page;
                          {
                            /* //console.log('activ statues' + is_active) */
                          }

                          //Handling files
                          const changeHandler = async (event) => {
                            setFile(event.target.files[0]);
                          };

                          //Handling updated values
                          const onChange = (e, row, _id) => {
                            const { name, value } = e.target;
                            if (name == "is_active") {
                              //console.log("came");
                              setIsActiveValue(true);
                            }
                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          const handleUpdate = async () => {
                            setLoading(true);

                            //console.log("Category" + updatedValues?.category);
                            //console.log(updatedValues);
                            let data;
                            {
                          
                            }
                            data = { ...updatedValues };
                            //console.log(data);
                            makeReq(data);
                          };
                          const makeReq = async (data) => {
                            //console.log(data);
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const formData = new FormData();
                            if (file) {
                              formData.append("image", file);
                            }

                            if (data.name) {
                              formData.append("name", data.name);
                            }
                            if (data.title) {
                              formData.append("title", data.title);
                            }
                            {
                              /* formData.append('BannerType', 'WEB') */
                            }

                            if (data.mobile) {
                              formData.append("mobile", data.mobile);
                            }
                            if (isActiveValue) {
                              formData.append("status", data.is_active);
                            }
                            console.log(formData);
                            const config = {
                              headers: {
                                "content-type": "multipart/form-data",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL}/mentor/update-mentors/${_id}`,
                              formData,
                              config
                            );
                            console.log(res);
                            setLoading(false);
                            toast.success("Updated Successfully");
                            fetchUsers()
                            setIsEdit(false)
                           // setTimeout(() => window.location.reload(), 800);
                          };

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === _id ? (
                                      <Input
                                        onChange={(e) => onChange(e, row, _id)}
                                        // value={value}
                                        name="name"
                                        placeholder={name}
                                      />
                                    ) : (
                                      <span>{name}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">
                                {isEdit && selectedId === _id ? (
                                  <Input
                                    onChange={(e) => onChange(e, row, _id)}
                                    // value={value}
                                    name="title"
                                    placeholder="Designation"
                                  />
                                ) : (
                                  <span>{row?.title}</span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                {isEdit && selectedId === _id ? (
                                  <>
                                    <input
                                      style={{
                                        display: "none",
                                      }}
                                      id="choose-file"
                                      type="file"
                                      name="file"
                                      onChange={changeHandler}
                                      // onChange={handleChange}
                                    />
                                    <label htmlFor="choose-file">
                                      <FileUploadIcon
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    </label>
                                  </>
                                ) : (
                                  <a
                                    href={image}
                                    style={{ color: "black" }}
                                    target="_blank"
                                  >
                                    <img
                                      src={image}
                                      style={{ width: "150px" }}
                                    ></img>
                                    {/* <ImageIcon

                                                            sx={{
                                                                cursor: 'pointer', '&:hover': {
                                                                    color: 'red'
                                                                }
                                                            }} /> */}
                                  </a>
                                )}
                              </TableCell>
       <TableCell align="left">{createdDate}</TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === _id ? (
                                  <TextField
                                    onChange={(e) => onChange(e, row)}
                                    size="small"
                                    label={status ? "Active" : "Inactive"}
                                    name="is_active"
                                    placeholder={status ? "Active" : "Inactive"}
                                    select
                                    sx={{ width: "100px" }}
                                  >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>Inactive</MenuItem>
                                  </TextField>
                                ) : (
                                  <span
                                    style={{
                                      background: status ? "green" : "red",
                                      padding: "5px",
                                      borderRadius: "10px",
                                      color: "white",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {status ? "Active" : "Inactive"}
                                  </span>
                                )}
                              </TableCell>
                              {isEdit && selectedId === _id ? (
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null}

                              <TableCell
                                align="right"
                                onClick={() => setSelectedId(_id)}
                              >
                                <UserMoreMenu
                                  id={_id}
                                  req="delete-mentors"
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
